import { defineComponent, onMounted, ref } from 'vue'
import { RouteLocationMatched, useRoute, useRouter } from 'vue-router'
import './index.scss'

/**
 * 面包屑
 * @type {*} */
const BreadCrumbs = defineComponent({
  setup() {
    const route = useRoute()
    const router = useRouter()

    const pathArr = ref([])

    function pathLink(): JSX.Element {
      return <div class="bread_crumbs">
        {pathArr.value.map((item: RouteLocationMatched, index: number) => (
          <>
            <span
              class={[
                'bread_crumbs-link pointer',
                index === pathArr.value.length - 1 && 'bread_crumbs-linkActive'
              ]}
              onClick={() => goPath(item)} >{ item.meta?.title }
            </span>
            { index !== pathArr.value.length - 1 && <i class="el-icon-arrow-right"/> }
          </>
        ))}
      </div>
    }

    function goPath(RouteLocationMatched: RouteLocationMatched): void {
      const currentPath = route.fullPath // 当前路由
      const params = route.params as any
      const linkPath = params.id ? RouteLocationMatched.path.replace(':id', params.id) : RouteLocationMatched.path
      if(linkPath === currentPath) {
        return
      } else {
        router.push(linkPath)
      }
    }

    onMounted(() => {
      const list = route.matched.slice(1) as never[]
      if(route.params?.nowObj) {
        const obj: any = JSON.parse(route.params.nowObj as string)
        list.splice(list.length - 1, 0, obj as never)
      }
      pathArr.value = list
    })
    return () => pathLink()
  }
})

export default BreadCrumbs
