import api from "@/api";
import BreadCrumbs from "@/components/BreadCrumbs";
import { parseTime, sharer } from "@/utils";
import { setup, Vue } from "vue-class-component";
import { SmsShow } from "..";
import './index.scss'
import 'vue-awesome/icons/brands/whatsapp-square';
import { MetaRow } from "@/models/type";
import { useMeta } from "vue-meta";

export default class ActivityShowDetail extends Vue {

  public showObj: SmsShow = {}
  public article: any = {}


  metaList: Array<MetaRow> = []

  meta = setup(() => {
    const { meta } = useMeta({
      meta: this.metaList
    })
    return meta
  })

  public mainDom(): JSX.Element {
    return <el-row style="position: relative;">
      <el-col xs={24} sm={24} md={12} lg={12} xl={12}>
        <div class="mian-info">
          <BreadCrumbs />
          <div class="mian-info-header">
            <div>{this.showObj.title}</div>
            <span>{parseTime(new Date(this.showObj.signUpStartTime as never).getTime(), '{y}.{m}.{d}')}</span>
          </div>
          <div class="mian-info-position"><i class="el-icon-location-outline"/><span>{this.showObj.position}</span></div>
          <p>{this.showObj.introduce}</p>
          {
            this.showObj.title
              && <div class="mian-info-fun">
                  <share-network
                    network="facebook"
                    url={window.location.href}
                    title={this.showObj.title}
                    description={this.showObj.introduce}
                  >
                    <div class="facebook"/>
                  </share-network>
                  <div class="wechat" onClick={() => sharer(2)}/>
                  <share-network
                    network="whatsapp"
                    url={window.location.href}
                    title={this.showObj.title}
                    description={this.showObj.introduce}
                  >
                    <div class="phone"/>
                  </share-network>
                </div>
          }
        </div>
      </el-col>
      <el-col xs={24} sm={24} md={12} lg={12} xl={12}>
        <img class="mian-main_img" src={this.showObj.mainImg} alt={this.showObj.title} />
      </el-col>
      <div class="main-art">
        <img src={this.article.imgUrl} alt="" />
        <div class="main-art-info">
          <div class="main-art-info-name">{this.article.title}</div>
          <div class="main-art-info-content">{this.article.introduce}</div>
        </div>
      </div>
    </el-row>
  }

  listDom(): JSX.Element {
    return <div class="activity_show_detail-list">
      <el-row gutter={40}>
        {this.showObj.otherImg?.split(',').map(item => (
          <el-col xs={24} sm={12} md={8} lg={8} xl={6}>
            <img src={item} alt="" />
          </el-col>
        ))}
      </el-row>
    </div>
  }

  getDetail(): void {
    const id = this.$route.params.id as never
    api.getShowById(id).then(res => {
      this.showObj = res.data.show
      this.article = res.data.article
      this.getMeta()
    })
  }

  getMeta(): void {
    this.metaList.splice(0, this.metaList.length)
    this.metaList.push(
      {property: 'og:title', content: this.showObj.title as string},
      {property: 'og:description', content: this.showObj.introduce as string},
      {property: 'og:image', content: this.showObj.coverImg as string}
    )
  }

  render(): JSX.Element {
    return <div class="activity_show_detail">
      {this.mainDom()}
      <div class="activity_show_detail-line">
        <div>更多展示</div>
      </div>
      {this.listDom()}
      <div class="activity_show_detail-bottom">
        <el-row gutter={60}>
          <el-col xs={24} sm={12} md={8} lg={8} xl={6}>
            <div class="activity_show_detail-bottom-cell">
              <div>場地租用</div>
              <div>尖沙咀科學館道9號，新東海商業中心1樓106-107室</div>
            </div>
          </el-col>
          <el-col xs={24} sm={12} md={8} lg={8} xl={6}>
            <div class="activity_show_detail-bottom-cell">
              <div>聯繫方式</div>
              <div>25282202</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  }

  mounted(): void {
    this.getDetail()
  }
}
